import { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import styles from '../styles/FeedbackBlock.module.css';
import IconAvatar from './icons/IconAvatar';
import IconBuilding from './icons/IconBuilding';
import IconConcierge from './icons/IconConcierge';
import IconEmailAlt from './icons/IconEmailAlt';
import IconEnvelope from './icons/IconEnvelope';
import IconMobile from './icons/IconMobile';
import IconPhone from './icons/IconPhone';
import IconPlace from './icons/IconPlace';
import IconQuestion from './icons/IconQuestion';

type Inputs = {
  yourName: string;
  email: string;
  phone?: string;
  mobile?: string;
  company?: string;
  location?: string;
  service?: string;
  reason?: string;
  message: string;
  address?: string;
  postcode?: string;
  optin?: boolean;
  hutk?: string;
  pageUri?: string;
  pageName?: string;
};

export default function FeedbackBlock() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<Inputs>({ mode: 'onChange' });

  const [validSubmission, setValidSubmission] = useState<boolean>(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const hutk = document.cookie.replace(
      /(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/,
      '$1'
    );

    const hData = data;
    if (hutk !== '') {
      hData.hutk = hutk;
      hData.pageUri = window.location.href;
      hData.pageName = document.title;
    }

    // Send data to server
    const response = await fetch('/api/feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(hData),
    });

    // Handle response
    if (response.status === 400) {
      const result = await response.json();
      setError(result.field, { type: 'custom', message: result.data });
    }

    // Handle response
    if (response.status === 200) {
      setValidSubmission(true);
    }
  };

  return (
    <div className={styles.form}>
      <h3>Leave Feedback</h3>

      {!validSubmission ? (
        <form action="/api/feedback" onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.form_row}>
            <div className={styles.form_item}>
              <label htmlFor="yourName">
                <span>Your Name</span>
                <div className={styles.input_wrap}>
                  <IconAvatar width={12} height={12} />
                  <input
                    type="text"
                    autoComplete="name"
                    className={errors.yourName && styles.error}
                    id="yourName"
                    aria-invalid={errors.yourName ? 'true' : 'false'}
                    {...register('yourName', {
                      required: true,
                      maxLength: 120,
                    })}
                  />
                </div>
              </label>
              {errors.yourName?.type === 'required' &&
                errors.yourName?.message === '' && (
                  <span className={styles.input_error}>
                    Your name is required
                  </span>
                )}
              {errors.yourName?.message && (
                <span className={styles.input_error}>
                  {errors.yourName.message}
                </span>
              )}
            </div>

            <div className={styles.form_item}>
              <label htmlFor="email">
                <span>Email</span>
                <div className={styles.input_wrap}>
                  <IconEmailAlt width={12} height={12} />
                  <input
                    type="email"
                    autoComplete="email"
                    className={errors.email && styles.error}
                    id="email"
                    aria-invalid={errors.email ? 'true' : 'false'}
                    {...register('email', {
                      required: true,
                      maxLength: 120,
                    })}
                  />
                </div>
              </label>
              {errors.email?.type === 'required' &&
                errors.email?.message === '' && (
                  <span className={styles.input_error}>
                    Your email is required
                  </span>
                )}
              {errors.email?.message && (
                <span className={styles.input_error}>
                  {errors.email.message}
                </span>
              )}
            </div>
          </div>

          <div className={styles.form_row}>
            <div className={styles.form_item}>
              <label htmlFor="company">
                <span>Company</span>
                <div className={styles.input_wrap}>
                  <IconBuilding width={12} height={12} />
                  <input
                    type="text"
                    autoComplete="organization"
                    className={errors.company && styles.error}
                    id="company"
                    aria-invalid={errors.company ? 'true' : 'false'}
                    {...register('company', {
                      maxLength: 120,
                    })}
                  />
                </div>
              </label>
              {errors.company?.message && (
                <span className={styles.input_error}>
                  {errors.company.message}
                </span>
              )}
            </div>

            <div className={styles.form_item}>
              <label htmlFor="location">
                <span>Location</span>
                <div className={styles.input_wrap}>
                  <IconPlace width={12} height={12} />
                  <select
                    defaultValue=""
                    className={errors.location && styles.error}
                    id="location"
                    aria-invalid={errors.location ? 'true' : 'false'}
                    {...register('location')}
                  >
                    <option value=""></option>
                    <option value="London - Blackfriars">
                      London - Blackfriars
                    </option>
                    <option value="London - Monument">London - Monument</option>
                    <option value="London - Waterloo">London - Waterloo</option>
                    <option value="London - Liverpool Street">
                      London - Liverpool Street
                    </option>
                    <option value="London - St Pauls and Farringdon">
                      London - St Pauls and Farringdon
                    </option>
                    <option value="edinburgEdinburgh - North David Streeth_north_david">
                      Edinburgh - North David Street
                    </option>
                    <option value="Cardiff - Castle Court">
                      Cardiff - Castle Court
                    </option>
                    <option value="Cardiff - Temple Court">
                      Cardiff - Temple Court
                    </option>
                  </select>
                </div>
              </label>
              {errors.location?.message && (
                <span className={styles.input_error}>
                  {errors.location.message}
                </span>
              )}
            </div>
          </div>

          <div className={styles.form_row}>
            <div className={styles.form_item}>
              <label htmlFor="phone">
                <span>Telephone</span>
                <div className={styles.input_wrap}>
                  <IconPhone width={12} height={12} />
                  <input
                    type="text"
                    autoComplete="tel"
                    className={errors.phone && styles.error}
                    id="phone"
                    aria-invalid={errors.phone ? 'true' : 'false'}
                    {...register('phone', {
                      maxLength: 120,
                    })}
                  />
                </div>
              </label>
              {errors.phone?.message && (
                <span className={styles.input_error}>
                  {errors.phone.message}
                </span>
              )}
            </div>

            <div className={styles.form_item}>
              <label htmlFor="mobile">
                <span>Mobile</span>
                <div className={styles.input_wrap}>
                  <IconMobile width={12} height={12} />
                  <input
                    type="text"
                    autoComplete="tel"
                    className={errors.mobile && styles.error}
                    id="mobile"
                    aria-invalid={errors.mobile ? 'true' : 'false'}
                    {...register('mobile', {
                      maxLength: 120,
                    })}
                  />
                </div>
              </label>
              {errors.mobile?.message && (
                <span className={styles.input_error}>
                  {errors.mobile.message}
                </span>
              )}
            </div>
          </div>

          <div className={styles.form_row}>
            <div className={styles.form_item}>
              <label htmlFor="service">
                <span>Service</span>
                <div className={styles.input_wrap}>
                  <IconConcierge width={12} height={12} />
                  <select
                    className={errors.service && styles.error}
                    defaultValue=""
                    id="service"
                    aria-invalid={errors.service ? 'true' : 'false'}
                    {...register('service')}
                  >
                    <option value=""></option>
                    <option value="Sales">Sales</option>
                    <option value="Operations">Operations</option>
                    <option value="Conferencing and Events">
                      Conferencing and Events
                    </option>
                    <option value="Reception">Reception</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </label>
              {errors.service?.message && (
                <span className={styles.input_error}>
                  {errors.service.message}
                </span>
              )}
            </div>

            <div className={styles.form_item}>
              <label htmlFor="reason">
                <span>Reason</span>
                <div className={styles.input_wrap}>
                  <IconQuestion width={12} height={12} />
                  <select
                    defaultValue=""
                    className={errors.reason && styles.error}
                    id="reason"
                    aria-invalid={errors.reason ? 'true' : 'false'}
                    {...register('reason')}
                  >
                    <option value=""></option>
                    <option value="General Question">General Question</option>
                    <option value="General Feedback">General Feedback</option>
                    <option value="Concerns and Complaints">
                      Concerns and Complaints
                    </option>
                    <option value="Compliments">Compliments</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </label>
              {errors.reason?.message && (
                <span className={styles.input_error}>
                  {errors.reason.message}
                </span>
              )}
            </div>
          </div>

          <div className={styles.form_item}>
            <label htmlFor="message">
              <span>Message</span>
              <div className={styles.input_wrap}>
                <IconEnvelope width={12} height={12} />
                <textarea
                  className={errors.message && styles.error}
                  id="message"
                  aria-invalid={errors.message ? 'true' : 'false'}
                  {...register('message', { required: true })}
                />
              </div>
            </label>
            {errors.message?.type === 'required' &&
              errors.message?.message === '' && (
                <span className={styles.input_error}>
                  Please enter a message
                </span>
              )}
            {errors.message?.message && (
              <span className={styles.input_error}>
                {errors.message.message}
              </span>
            )}
          </div>

          <div className={styles.form_item}>
            <label htmlFor="optin" className="!flex items-center">
              <input id="optin" type="checkbox" {...register('optin')} />
              <span className="inline-block ml-3">
                I agree to receive other communications from Office Space in
                Town.
              </span>
            </label>
          </div>

          <label htmlFor="address" className={styles.address}>
            Address
            <input
              type="text"
              tabIndex={-1}
              autoComplete="off"
              id="address"
              aria-invalid={errors.address ? 'true' : 'false'}
              {...register('address')}
            />
          </label>
          {errors.address?.message && (
            <span className={styles.input_error}>{errors.address.message}</span>
          )}

          <label htmlFor="postcode" className={styles.postcode}>
            Postcode
            <input
              type="text"
              tabIndex={-1}
              autoComplete="off"
              id="postcode"
              aria-invalid={errors.postcode ? 'true' : 'false'}
              {...register('postcode')}
            />
          </label>
          {errors.postcode && errors.postcode?.message === '' && (
            <span className={styles.input_error}>
              There was an error submitting the form
            </span>
          )}

          <button type="submit">Send</button>
        </form>
      ) : (
        <div className={styles.success}>
          Your message has been sent successfully
        </div>
      )}
    </div>
  );
}
