import React from 'react';

type Props = {
  className?: string;
  fill?: string;
  height?: number;
  width?: number;
};

export default function IconConcierge(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      height={props.width}
      width={props.width}
      className={props.className}
    >
      <path
        fill={props.fill}
        d="M504 416h-24v-64c0-118.31-91.79-214.96-208-223.19V96h40c4.42 0 8-3.58 8-8V72c0-4.42-3.58-8-8-8H200c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h40v32.81C123.79 137.04 32 233.69 32 352v64H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h496c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zm-56 0H64v-64c0-100.17 78.3-184.19 178.26-191.27 16.87-1.19 10.33-1.21 27.48 0C369.7 167.81 448 251.83 448 352v64z"
      />
    </svg>
  );
}
