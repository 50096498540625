import { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import styles from '../styles/NewsletterBlock.module.css';

type Inputs = {
  email: string;
  address?: string;
  postcode?: string;
  hutk?: string;
  pageUri?: string;
  pageName?: string;
};

export default function NewsletterBlock() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<Inputs>({ mode: 'onChange' });

  const [validSubmission, setValidSubmission] = useState<boolean>(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const hutk = document.cookie.replace(
      /(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/,
      '$1'
    );

    const hData = data;
    if (hutk !== '') {
      hData.hutk = hutk;
      hData.pageUri = window.location.href;
      hData.pageName = document.title;
    }

    // Send data to server
    const response = await fetch('/api/newsletter', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(hData),
    });

    // Handle response
    if (response.status === 400) {
      const result = await response.json();
      setError(result.field, { type: 'custom', message: result.data });
    }

    // Handle response
    if (response.status === 200) {
      setValidSubmission(true);
    }
  };

  return (
    <>
      {!validSubmission ? (
        <form action="/api/newsletter" onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.form_item}>
            <label htmlFor="email">
              <span>Get an update every week from OSiT</span>
              <div className={styles.input_wrap}>
                <input
                  type="email"
                  autoComplete="email"
                  className={errors.email && styles.error}
                  id="email"
                  aria-invalid={errors.email ? 'true' : 'false'}
                  {...register('email', { required: true, maxLength: 120 })}
                />
              </div>
            </label>
            {errors.email?.type === 'required' &&
              errors.email?.message === '' && (
                <span className={styles.input_error}>
                  Your email is required
                </span>
              )}
            {errors.email?.message && (
              <span className={styles.input_error}>{errors.email.message}</span>
            )}
          </div>

          <label htmlFor="address" className={styles.address}>
            Address
            <input
              type="text"
              tabIndex={-1}
              autoComplete="off"
              id="address"
              aria-invalid={errors.address ? 'true' : 'false'}
              {...register('address')}
            />
          </label>
          {errors.address?.message && (
            <span className={styles.input_error}>{errors.address.message}</span>
          )}

          <label htmlFor="postcode" className={styles.postcode}>
            Postcode
            <input
              type="text"
              tabIndex={-1}
              autoComplete="off"
              id="postcode"
              aria-invalid={errors.postcode ? 'true' : 'false'}
              {...register('postcode')}
            />
          </label>
          {errors.postcode && errors.postcode?.message === '' && (
            <span className={styles.input_error}>
              There was an error submitting the form
            </span>
          )}

          <button type="submit" className={styles.submit}>
            Subscribe
          </button>
        </form>
      ) : (
        <div className={styles.success}>You have successfully subscribed</div>
      )}
    </>
  );
}
