import React from 'react';

type Props = {
  className?: string;
  fill?: string;
  height?: number;
  width?: number;
};

export default function IconEmail(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 32"
      height={props.height}
      width={props.width}
      className={props.className}
    >
      <path
        fill={props.fill}
        d="M.064 24.032V5.6q0-.032.096-.608l10.848 9.28-10.816 10.4q-.128-.448-.128-.64zm1.44-20.32q.224-.096.544-.096h29.216q.288 0 .576.096l-10.88 9.312-1.44 1.152-2.848 2.336-2.848-2.336-1.44-1.152zm.032 22.208 10.912-10.464 4.224 3.424 4.224-3.424L31.808 25.92q-.256.096-.544.096H2.048q-.256 0-.512-.096zm20.8-11.648 10.816-9.28q.096.288.096.608v18.432q0 .288-.096.64z"
      />
    </svg>
  );
}
