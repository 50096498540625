import React from 'react';

type Props = {
  className?: string;
  fill?: string;
  height?: number;
  width?: number;
};

export default function IconEmailAlt(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9.261 7.408"
      height={props.height}
      width={props.width}
      className={props.className}
    >
      <path
        fill={props.fill}
        d="M8.335 0a.95.95 0 0 1 .926.926v5.556a.95.95 0 0 1-.926.926H.926A.95.95 0 0 1 0 6.482V.926A.95.95 0 0 1 .926 0Zm0 1.852V.926l-3.7 2.315L.935.926v.926l3.7 2.315Z"
      />
    </svg>
  );
}
