import React from 'react';

type Props = {
  className?: string;
  fill?: string;
  height?: number;
  width?: number;
};

export default function IconPlace(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 83.643 104.553"
      height={props.height}
      width={props.width}
      className={props.className}
    >
      <path
        fill={props.fill}
        d="M41.821 52.277a10.455 10.455 0 1 1 10.456-10.456 10.486 10.486 0 0 1-10.456 10.456Zm31.366-9.41c0-18.976-13.853-32.411-31.366-32.411S10.455 23.89 10.455 42.867c0 12.233 10.194 28.438 31.366 47.781C62.993 71.305 73.187 55.1 73.187 42.867ZM41.821 0c21.956 0 41.822 16.833 41.822 42.867q0 26.034-41.821 61.686Q.026 68.874 0 42.867C0 16.833 19.865 0 41.821 0Z"
      />
    </svg>
  );
}
