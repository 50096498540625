import React from 'react';

type Props = {
  className?: string;
  fill?: string;
  height?: number;
  width?: number;
};

export default function IconMobile(props: React.SVGProps<Props>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 6.483 10.187"
      height={props.height}
      width={props.width}
      className={props.className}
    >
      <path
        fill={props.fill}
        d="M5.556 0a.95.95 0 0 1 .926.926v8.335a.95.95 0 0 1-.926.926H.926A.95.95 0 0 1 0 9.261V.926A.95.95 0 0 1 .926 0Zm0 8.335V1.852H.926v6.483Z"
      />
    </svg>
  );
}
