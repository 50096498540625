import Link from 'next/link';
import React from 'react';
import Modal from 'react-modal';

import styles from '../styles/Footer.module.css';
import FeedbackBlock from './FeedbackBlock';
import IconClose from './icons/IconClose';
import Logo from './Logo';
import NewsletterBlock from './NewsletterBlock';
import SocialNav from './SocialNav';
import Container from './ui/Container';

export default function Footer() {
  Modal.setAppElement('#modals');
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <footer className={styles.footer}>
      <Container size="lg">
        <div className={styles.first}>
          <div className={styles.col1}>
            <Logo color="light" width={120} />
            <SocialNav />
          </div>

          <div className={styles.col2}>
            <nav>
              <h3>Services</h3>

              <ul>
                <li>
                  <Link href="/london-serviced-offices">
                    &gt; London Serviced Offices
                  </Link>
                </li>
                <li>
                  <Link href="/serviced-offices">&gt; Serviced Offices</Link>
                </li>
                <li>
                  <Link href="/virtual-offices">&gt; Virtual Offices</Link>
                </li>
                <li>
                  <Link href="/meeting-rooms">&gt; Meeting Rooms</Link>
                </li>
                <li>
                  <Link href="/venues-for-events">&gt; Venues for Events</Link>
                </li>
                <li>
                  <Link href="/the-cabins">&gt; Serviced Bedrooms</Link>
                </li>
              </ul>
            </nav>
          </div>

          <div className={styles.col3}>
            <h3>Contact Info</h3>
            <address>
              20 St Dunstan`s Hill
              <br />
              London, EC3R 8HL
            </address>
            <p>
              <span className={styles.highlight}>Phone: </span>
              <br /> <Link href="tel:+448444121796">0844 412 1796</Link>
            </p>
            <p>
              <span className={styles.highlight}>Email:</span>
              <br />
              <Link href="mailto:sales@officespaceintown.com">
                sales@officespaceintown.com
              </Link>
            </p>
          </div>

          <div className={styles.col4}>
            <h3>Newsletter</h3>
            <NewsletterBlock />
          </div>
        </div>

        <div className={styles.final}>
          <div>
            Copyright &copy; {new Date().getFullYear()}{' '}
            <span className={styles.highlight}>OSiT</span>. All Rights Reserved.
          </div>

          <div>
            <Link href="/terms-use">Terms of Use</Link> |{' '}
            <Link href="/privacy-policy">Privacy Policy</Link> |{' '}
            <Link href="/cookies-policy">Cookies Policy</Link> |{' '}
            <button onClick={() => openModal()} type="button">
              Feedback
            </button>
            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              className={styles.modal}
              overlayClassName={styles.modal_overlay}
              shouldCloseOnOverlayClick={true}
            >
              <div className={styles.modal_body}>
                <button
                  onClick={closeModal}
                  className={styles.modal_close}
                  type="button"
                >
                  <IconClose fill="#fff" width={12} />
                </button>
                <FeedbackBlock />
              </div>
            </Modal>
          </div>
        </div>
      </Container>
    </footer>
  );
}
