import React from 'react';

type Props = {
  className?: string;
  fill?: string;
  height?: number;
  width?: number;
};

export default function IconPhone(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8.335 8.335"
      height={props.height}
      width={props.width}
      className={props.className}
    >
      <path
        fill={props.fill}
        d="M1.667 3.565a6.963 6.963 0 0 0 3.056 3.056l1.019-1.019a.369.369 0 0 1 .463-.093 4.843 4.843 0 0 0 1.667.278.475.475 0 0 1 .463.463v1.621a.475.475 0 0 1-.463.463A7.9 7.9 0 0 1 0 .462a.475.475 0 0 1 .463-.463h1.621a.475.475 0 0 1 .463.463 5.244 5.244 0 0 0 .278 1.667.506.506 0 0 1-.093.463Z"
      />
    </svg>
  );
}
