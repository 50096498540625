import React from 'react';

type Props = {
  className?: string;
  fill?: string;
  height?: number;
  width?: number;
};

export default function IconAvatar(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8.733 8.733"
      height={props.height}
      width={props.width}
      className={props.className}
    >
      <path
        fill={props.fill}
        d="M4.366 1.037A1.146 1.146 0 1 1 3.22 2.183a1.146 1.146 0 0 1 1.146-1.146m0 4.912c1.621 0 3.329.8 3.329 1.146v.6H1.037v-.6c0-.349 1.708-1.146 3.329-1.146m0-5.949a2.183 2.183 0 1 0 2.183 2.183A2.183 2.183 0 0 0 4.366 0Zm0 4.912C2.909 4.912 0 5.643 0 7.1v1.637h8.733V7.1c0-1.457-2.909-2.188-4.367-2.188Z"
      />
    </svg>
  );
}
