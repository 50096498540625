import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import menuTeam from '../../public/images/menu-about-us.png';
import menuAdjust from '../../public/images/menu-adjust.png';
import menuRoomBlackfriars from '../../public/images/menu-blackfriars-meeting-room.png';
import menuOfficeBlackfriars from '../../public/images/menu-blackfriars-serviced-office.png';
import menuRoomCardiff from '../../public/images/menu-cardiff-meeting-room.png';
import menuOfficeCardiff from '../../public/images/menu-cardiff-serviced-office.png';
import menuCareers from '../../public/images/menu-careers.png';
import menuRoomEdinburgh from '../../public/images/menu-edinburgh-meeting-room.png';
import menuOfficeEdinburgh from '../../public/images/menu-edinburgh-serviced-office.png';
import menuRoomLiverpoolst from '../../public/images/menu-liverpool-st-meeting-room.png';
import menuOfficeLiverpoolst from '../../public/images/menu-liverpool-st-serviced-office.png';
import menuRoomMonument from '../../public/images/menu-monument-meeting-room.png';
import menuOfficeMonument from '../../public/images/menu-monument-serviced-office.png';
import menuRoomStpauls from '../../public/images/menu-st-pauls-meeting-room.png';
import menuOfficeStpauls from '../../public/images/menu-st-pauls-serviced-office.png';
import menuCabins from '../../public/images/menu-the-cabins.png';
import menuDeck from '../../public/images/menu-the-deck.png';
import menuVirtual from '../../public/images/menu-virtual-offices.png';
import menuRoomWaterloo from '../../public/images/menu-waterloo-meeting-room.png';
import menuOfficeWaterloo from '../../public/images/menu-waterloo-serviced-office.png';
import styles from '../styles/Nav.module.css';
import IconBurgerMenu from './icons/IconBurgerMenu';
import NavDropdown from './NavDropdown';
import NavStub from './NavStub';
import Btn from './ui/Button';

export default function Nav() {
  const navRef = useRef<HTMLDivElement>(null);

  const [menuServicedOfficeOpen, setMenuServicedOfficeOpen] =
    useState<boolean>(false);
  const [menuMeetingRoomOpen, setMenuMeetingRoomOpen] =
    useState<boolean>(false);
  const [menuServicesOpen, setMenuServicesOpen] = useState<boolean>(false);
  const [menuAboutOpen, setMenuAboutOpen] = useState<boolean>(false);
  const [mobileOpen, setMobileOpen] = useState<string>('hidden');
  const router = useRouter();

  function handleServicedOfficeClick(event: any) {
    event.preventDefault();
    setMenuServicedOfficeOpen(!menuServicedOfficeOpen);

    if (!menuServicedOfficeOpen) {
      setMenuMeetingRoomOpen(false);
      setMenuServicesOpen(false);
      setMenuAboutOpen(false);
    }
  }
  function handleMeetingRoomClick(event: any) {
    event.preventDefault();
    setMenuMeetingRoomOpen(!menuMeetingRoomOpen);

    if (!menuMeetingRoomOpen) {
      setMenuServicedOfficeOpen(false);
      setMenuServicesOpen(false);
      setMenuAboutOpen(false);
    }
  }
  function handleServicesClick(event: any) {
    event.preventDefault();
    setMenuServicesOpen(!menuServicesOpen);

    if (!menuServicesOpen) {
      setMenuServicedOfficeOpen(false);
      setMenuMeetingRoomOpen(false);
      setMenuAboutOpen(false);
    }
  }
  function handleAboutClick(event: any) {
    event.preventDefault();
    setMenuAboutOpen(!menuAboutOpen);

    if (!menuAboutOpen) {
      setMenuServicedOfficeOpen(false);
      setMenuMeetingRoomOpen(false);
      setMenuServicesOpen(false);
    }
  }
  function openMobileMenu() {
    setMobileOpen(mobileOpen === 'hidden' ? 'block' : 'hidden');
  }

  function closeAll() {
    setMenuAboutOpen(false);
    setMenuServicedOfficeOpen(false);
    setMenuMeetingRoomOpen(false);
    setMenuServicesOpen(false);
  }

  useEffect(() => {
    const checkIfClickedOutside = (event: any) => {
      if (
        mobileOpen &&
        navRef.current &&
        !navRef.current?.contains(event.target)
      ) {
        setMobileOpen('hidden');
      }

      if (
        menuServicedOfficeOpen &&
        navRef.current &&
        !navRef.current?.contains(event.target)
      ) {
        setMenuServicedOfficeOpen(false);
      }

      if (
        menuMeetingRoomOpen &&
        navRef.current &&
        !navRef.current?.contains(event.target)
      ) {
        setMenuMeetingRoomOpen(false);
      }

      if (
        menuServicesOpen &&
        navRef.current &&
        !navRef.current?.contains(event.target)
      ) {
        setMenuServicesOpen(false);
      }

      if (
        menuAboutOpen &&
        navRef.current &&
        !navRef.current?.contains(event.target)
      ) {
        setMenuAboutOpen(false);
      }
    };

    document.addEventListener('click', checkIfClickedOutside);
    window.addEventListener('resize', closeAll);
    router.events.on('routeChangeStart', closeAll);

    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, [
    menuServicedOfficeOpen,
    menuMeetingRoomOpen,
    menuServicesOpen,
    menuAboutOpen,
    navRef,
  ]);

  return (
    <nav ref={navRef}>
      <button
        className={styles.menu_btn}
        onClick={openMobileMenu}
        type="button"
      >
        <IconBurgerMenu width={24} height={24} />
        <span>MENU</span>
      </button>

      <ul className={cn(mobileOpen, styles.menu)}>
        <li className={styles.offices}>
          <Link href="/serviced-offices/" onClick={handleServicedOfficeClick}>
            Our Serviced Offices
          </Link>
          <NavDropdown
            isOpen={menuServicedOfficeOpen}
            className="offices"
            main={
              <>
                <div className={styles.title}>London Service Offices</div>
                <ul className={styles.main_ul}>
                  <li className="sm:w-1/2">
                    <NavStub
                      title="Blackfriars"
                      subtitle="LONDON EC4Y"
                      link="/serviced-offices/london-blackfriars/"
                      image={menuOfficeBlackfriars}
                    />
                  </li>
                  <li className="sm:w-1/2">
                    <NavStub
                      title="St. Paul's"
                      subtitle="LONDON EC1A"
                      link="/serviced-offices/london-st-pauls/"
                      image={menuOfficeStpauls}
                    />
                  </li>
                  <li className="sm:w-1/2">
                    <NavStub
                      title="Liverpool St"
                      subtitle="LONDON EC2M"
                      link="/serviced-offices/london-liverpool-street/"
                      image={menuOfficeLiverpoolst}
                    />
                  </li>
                  <li className="sm:w-1/2">
                    <NavStub
                      title="Waterloo"
                      subtitle="LONDON SE1"
                      link="/serviced-offices/london-waterloo/"
                      image={menuOfficeWaterloo}
                    />
                  </li>
                  <li className="sm:w-1/2">
                    <NavStub
                      title="Monument"
                      subtitle="LONDON EC3R"
                      link="/serviced-offices/london-monument/"
                      image={menuOfficeMonument}
                    />
                  </li>
                </ul>
              </>
            }
            sidebar={
              <>
                <div className={styles.title}>Regional Serviced Offices</div>
                <ul>
                  <li>
                    <NavStub
                      title="Cardiff"
                      subtitle="CARDIFF CF11"
                      link="/serviced-offices/cardiff/"
                      image={menuOfficeCardiff}
                    />
                  </li>
                  <li>
                    <NavStub
                      title="Edinburgh"
                      subtitle="EDINBURGH EH2"
                      link="/serviced-offices/edinburgh/"
                      image={menuOfficeEdinburgh}
                    />
                  </li>
                </ul>
              </>
            }
          />
        </li>
        <li className={styles.rooms}>
          <Link href="/meeting-rooms/" onClick={handleMeetingRoomClick}>
            Book a Meeting Room
          </Link>
          <NavDropdown
            isOpen={menuMeetingRoomOpen}
            className="rooms"
            main={
              <>
                <div className={styles.title}>London Meeting Rooms</div>
                <ul className={styles.main_ul}>
                  <li className="sm:w-1/2">
                    <NavStub
                      title="Blackfriars"
                      subtitle="LONDON EC4Y"
                      link="/meeting-rooms/london-blackfriars/"
                      image={menuRoomBlackfriars}
                    />
                  </li>
                  <li className="sm:w-1/2">
                    <NavStub
                      title="St. Paul's"
                      subtitle="LONDON EC1A"
                      link="/meeting-rooms/london-st-pauls/"
                      image={menuRoomStpauls}
                    />
                  </li>
                  <li className="sm:w-1/2">
                    <NavStub
                      title="Liverpool St"
                      subtitle="LONDON EC2M"
                      link="/meeting-rooms/london-liverpool-street/"
                      image={menuRoomLiverpoolst}
                    />
                  </li>
                  <li className="sm:w-1/2">
                    <NavStub
                      title="Waterloo"
                      subtitle="LONDON SE1"
                      link="/meeting-rooms/london-waterloo/"
                      image={menuRoomWaterloo}
                    />
                  </li>
                  <li className="sm:w-1/2">
                    <NavStub
                      title="Monument"
                      subtitle="LONDON EC3R"
                      link="/meeting-rooms/london-monument/"
                      image={menuRoomMonument}
                    />
                  </li>
                  <li className="mt-2 sm:flex sm:items-end sm:w-1/2 sm:pb-2 sm:mt-0">
                    <Btn
                      href="https://osit.officernd.com/public/calendar/"
                      color="secondary"
                      size="sm"
                      target="_blank"
                      rel="noopener"
                    >
                      Online Booking
                    </Btn>
                  </li>
                </ul>
              </>
            }
            sidebar={
              <>
                <div className={styles.title}>Regional Meeting Rooms</div>
                <ul>
                  <li>
                    <NavStub
                      title="Cardiff"
                      subtitle="CARDIFF CF11"
                      link="/meeting-rooms/cardiff/"
                      image={menuRoomCardiff}
                    />
                  </li>
                  <li>
                    <NavStub
                      title="Edinburgh"
                      subtitle="EDINBURGH EH2"
                      link="/meeting-rooms/edinburgh/"
                      image={menuRoomEdinburgh}
                    />
                  </li>
                </ul>
              </>
            }
          />
        </li>
        <li className={styles.services}>
          <button onClick={handleServicesClick} type="button">
            Other Services
          </button>
          <NavDropdown
            isOpen={menuServicesOpen}
            className="services"
            main={
              <>
                <ul className={styles.main_ul}>
                  <li className="sm:w-1/2">
                    <NavStub
                      title="Adjust Day Offices"
                      subtitle="Flexible Office Solutions"
                      link="/adjust-day-offices/"
                      image={menuAdjust}
                    />
                  </li>
                  <li className="sm:w-1/2">
                    <NavStub
                      title="The Deck"
                      subtitle="Rooftop, riverside event venue"
                      link="/the-deck/"
                      image={menuDeck}
                    />
                  </li>
                  <li className="sm:w-1/2">
                    <NavStub
                      title="Virtual Offices"
                      link="/virtual-offices/"
                      image={menuVirtual}
                    />
                  </li>
                  <li className="sm:w-1/2">
                    <NavStub
                      title="The Cabins"
                      subtitle="Luxury serviced bedrooms"
                      link="/the-cabins/"
                      image={menuCabins}
                    />
                  </li>
                </ul>
              </>
            }
          />
        </li>
        <li className={styles.about_us}>
          <Link
            href="/meet-the-team/"
            onClick={handleAboutClick}
            className={styles.highlight}
          >
            About us
          </Link>
          <NavDropdown
            isOpen={menuAboutOpen}
            className="about"
            main={
              <>
                <ul className={styles.main_ul}>
                  <li className="sm:w-1/2">
                    <NavStub
                      title="Meet Our Team"
                      link="/meet-the-team/"
                      image={menuTeam}
                    />
                  </li>
                  <li className="sm:w-1/2">
                    <NavStub
                      title="Join the OSiT Family"
                      link="/careers/"
                      image={menuCareers}
                    />
                  </li>
                </ul>

                <ul className={styles.main_ul}>
                  <li className={styles.main_list}>
                    <Link href="/our-core-values/">Our Core Values</Link>
                    <Link href="/testimonials/">Testimonials</Link>
                  </li>
                  <li className={styles.main_list}>
                    <Link href="/customer-service/">Customer Service</Link>
                    <Link href="/case-studies/">Case Studies</Link>
                  </li>
                  <li className={styles.main_list}>
                    <Link href="/our-story/">Our Story</Link>
                    <Link href="/covid-safe-workplace/">
                      Covid Safe Workplace
                    </Link>
                  </li>
                </ul>
              </>
            }
            footer={
              <>
                <Link href="/esg/">
                  ESG - Environmental, Social and Governance
                </Link>

                <Link href="/the-osit-menopause-policy/">
                  Menopause Guidance Policy
                </Link>

                <Link href="/faq/">Frequently Asked Questions</Link>
              </>
            }
          />
        </li>
        <li>
          <Link href="/news/" className={styles.highlight}>
            News
          </Link>
        </li>
        <li>
          <Link href="/contact/" className={styles.highlight}>
            Contact us
          </Link>
        </li>
      </ul>
    </nav>
  );
}
