import React from 'react';

/**
 * Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License)
 */

type Props = {
  className?: string;
  fill?: string;
  height?: number;
  width?: number;
};

export default function IconFacebookCircle(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 175.8 178.33"
      height={props.height}
      width={props.width}
      className={props.className}
    >
      <path
        fill={props.fill}
        d="M87.9-.108c117.21 2 117.19 176.35 0 178.33-117.21-2-117.19-176.35 0-178.33Zm29.15 28.2c-34.24-.88-47.64-.64-44.58 39.95H58.89v21.61h14v62.2h25.72v-62.62h16.98l1.54-21.26H98.17c-.76-20.48.32-18.68 18.9-18.54v-21.34h-.03Z"
      />
    </svg>
  );
}
