import type { Argument } from 'classnames';
import cn from 'classnames';
import Image from 'next/image';

import logoImgDark from '../../public/logo-dark.png';
import logoImgLight from '../../public/logo-light.png';
import styles from '../styles/Logo.module.css';

export type Props = {
  [x: string]: Argument;
  color?: 'dark' | 'light';
  width?: number;
};

export default function Logo(props: Props) {
  return (
    <Image
      className={cn(
        props.className,
        props.color === 'light' ? styles.light : styles.dark
      )}
      width={props.color === 'light' ? 120 : 165}
      height={props.color === 'light' ? 75 : 103}
      src={props.color === 'light' ? logoImgLight : logoImgDark}
      alt="Office Space in Town - serviced offices"
    />
  );
}
