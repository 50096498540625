import type { PropsWithChildren, ReactElement } from 'react';

import Footer from '@/components/Footer';
import Header from '@/components/Header';

function LayoutMain({ children }: PropsWithChildren<{}>): ReactElement {
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <Header />
      <main className="mb-16">{children}</main>
      <Footer />
    </div>
  );
}
export default LayoutMain;
