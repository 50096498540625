import React from 'react';

type Props = {
  className?: string;
  fill?: string;
  height?: number;
  width?: number;
};

export default function IconEnvelope(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10.689 8.551"
      height={props.height}
      width={props.width}
      className={props.className}
    >
      <path
        fill={props.fill}
        d="M10.689 1.069A1.072 1.072 0 0 0 9.62 0H1.069A1.072 1.072 0 0 0 0 1.069v6.413a1.072 1.072 0 0 0 1.069 1.069H9.62a1.072 1.072 0 0 0 1.069-1.069Zm-1.069 0L5.344 3.741 1.069 1.069Zm0 6.413H1.069V2.138L5.344 4.81 9.62 2.138Z"
      />
    </svg>
  );
}
