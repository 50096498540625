import cn from 'classnames';
import type { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  className?: string;
}

export default function Container({ children, className, size }: Props) {
  const maxWidth = {
    sm: 'max-w-screen-sm',
    md: 'max-w-screen-md',
    lg: 'max-w-screen-lg',
    xl: 'max-w-screen-xl',
    '2xl': 'max-w-screen-2xl',
  };
  return (
    <div
      className={cn(
        'container',
        size ? maxWidth[size] : '',
        'mx-auto px-5',
        className
      )}
    >
      {children}
    </div>
  );
}
