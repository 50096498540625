import cn from 'classnames';
import type { ReactNode } from 'react';

import styles from '../styles/NavDropdown.module.css';

interface Props {
  isOpen: boolean;
  className?: string;
  main?: ReactNode;
  sidebar?: ReactNode;
  footer?: ReactNode;
}

export default function NavDropdown({
  isOpen = false,
  className,
  main,
  sidebar,
  footer,
}: Props) {
  return (
    <div
      className={cn(
        styles.dropdown,
        styles[`${className}`],
        isOpen ? styles.active : undefined
      )}
    >
      <div className={styles.arrow}></div>
      <div className={styles.window}>
        {sidebar && (
          <div className={styles.wrap}>
            <div className={styles.main}>{main}</div>
            <div className={styles.sidebar}>{sidebar}</div>
          </div>
        )}
        {!sidebar && <div className={styles.main}>{main}</div>}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </div>
  );
}
